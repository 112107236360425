<template>
  <a-modal v-model="editAdxSyncVisible" title="修改价格策略" @onCancel="handleCancel" @ok="handleSubmit" width="800px">
    <a-form-model ref="ruleForm" :model="query" v-bind="layout">
      <a-form-model-item label="广告源名称" prop="name">{{ query.name }}</a-form-model-item>
      <!-- 媒体出价策略 -->
      <a-form-model-item prop="settleStrategy">
        <m-tips
          slot="label"
          :content="`该功能为广告源设定预期的利润率，以确保广告源在Medatiom ADX获取流量的同时能够盈利。<br/>
策略：<br/>
(1)默认：使用广告响应的价格直接出价，不保留利润。<br/>
(2)百分比浮动：对广告响应的价格按百分比向下浮动后出价。浮动比例即预期利润率。<br/>
(3)自定义：输入一个固定的价格作为出价。此策略不考虑利润，视场景使用。`"
          :title="'媒体出价策略'"
          :width="250"
          placement="bottom"
        ></m-tips>
        <el-radio-group v-model="query.settleStrategy" size="medium">
          <el-radio label="0" border>默认</el-radio>
          <el-radio label="1" border>百分比浮动</el-radio>
          <el-radio label="2" border>平台自定义</el-radio>
        </el-radio-group>
      </a-form-model-item>
      <!-- 浮动比例 -->
      <a-form-model-item
        label="价格浮动"
        prop="settleValue"
        v-if="+query.settleStrategy === 1"
        :rules="[{ required: true, validator: validatorSettleValue, trigger: 'blur' }]"
      >
        <a-input style="width: 200px" placeholder="请输入价格浮动" v-model.number.trim="query.settleValue">
          <span slot="addonAfter">%</span>
        </a-input>
      </a-form-model-item>
      <!-- 结算底价 -->
      <a-form-model-item
        label="结算底价"
        prop="settleValue"
        :rules="[{ required: true, validator: validatorSettleBidfloor, trigger: 'blur' }]"
        v-if="+query.settleStrategy === 2"
      >
        <a-input style="width: 200px" placeholder="请输入结算底价" v-model.number.trim="query.settleValue">
          <span slot="addonAfter">元</span>
        </a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateSettleStrategy } from '@/api/aggregate'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 }
      },
      query: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    adxInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.query = JSON.parse(JSON.stringify(this.adxInfo))
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    editAdxSyncVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    validatorSettleBidfloor (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入结算底价！'))
      } else if (value < 0.01) {
        return callback(new Error('必须大于等于0.01！'))
      } else {
        return callback()
      }
    },

    validatorSettleValue (rule, value, callback) {
      if (!value && value !== 0) {
        return callback(new Error('请输入浮动比例！'))
      } else if (value < 0.01) {
        return callback(new Error('浮动比例必须大于0.01！'))
      } else if (value > 100) {
        return callback(new Error('浮动比例必须小于100！'))
      } else {
        return callback()
      }
    },
    handleCancel () {
      this.editAdxSyncVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          updateSettleStrategy(this.query).then((resp) => {
            this.$message.success('编辑成功！')
            this.editAdxSyncVisible = false
            this.$emit('editSuccess', this.query)
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .el-radio.is-bordered {
    margin-right: 0px;
  }
  .el-radio.is-bordered.is-checked {
    background: fade(@primary-color, 10%);
    border-radius: 5px;
    border: 1px solid fade(@primary-color,  30%);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: @primary-color;
  }
  .el-radio__input {
    display: none;
  }
}
</style>
