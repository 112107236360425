import request from '@/utils/request'

const api = {
  // 获取账户列表
  getPlatAccounts: '/plat/accounts/',
  // 添加新账户
  // 获取模板类型列表
  gettemplateTypeList: '/aggregate/templateTypeList',
  // 移除配置
  removeConfigured: '/rtbAd/removeConfigured',
  // 批量(流量包)
  configured2: '/rtbAd/configured2',
  // api_platform
  apiPlatform: '/rtbAd/api_platform_list',
  // 列表
  report: '/rtbAd/report',
  // 新增
  addRtb: '/rtbAd/add',
  // 编辑配置
  updateConfigured: 'rtbAd/updateConfigured',
  // 批量编辑
  updateConfigured2: 'rtbAd/updateConfigured2',
  // 编辑
  updateRtb: '/rtbAd/update',
  // 广告主样式
  platPosition: '/rtbAd/platPosition',
  // 编辑应用ID
  // updateRtbPlatAppId: '/rtbAd/',
  // 广告平台
  platFormList: '/plat/platFormList',
  // 竞价广告源状态开关
  updateConfiguredSts: '/rtbAd/updateConfiguredSts',
  // 获取预算广告位子表
  reportDetails: '/rtbAd/reportDetails',
  // 修改dsp广告位状态
  updateDspSts: '/rtbAd/updateSts',
  // 批量修改dsp广告位状态
  batchChangePlaceSts: '/rtbAd/updateConfiguredSts',
  // 获取账户历史替换包名等信息
  replacerInfo: '/rtbAd/replacerInfo'
}

export default api
export function getPlatAccounts (data) {
  return request({
    url: api.getPlatAccounts,
    method: 'post',
    data
  })
}
export function gettemplateTypeList () {
  return request({
    url: api.gettemplateTypeList,
    method: 'get'
  })
}
// 移除配置
export function removeConfigured (data) {
  return request({
    url: api.removeConfigured,
    method: 'post',
    data
  })
}
export function configured2 (data) {
  return request({
    url: api.configured2,
    method: 'post',
    data
  })
}
// 列表
export function dspSourceReport (data) {
  return request({
    url: api.report,
    method: 'post',
    data
  })
}

// 广告主样式
export function getPlatPosition (data) {
  return request({
    url: api.platPosition,
    method: 'post',
    data
  })
}

// 新增广告源
export function addRtb (data) {
  return request({
    url: api.addRtb,
    method: 'post',
    data
  })
}
export function updateConfigured (data) {
  return request({
    url: api.updateConfigured,
    method: 'post',
    data
  })
}
export function updateConfigured2 (data) {
  return request({
    url: api.updateConfigured2,
    method: 'post',
    data
  })
}

// 新增广告源
export function updateRtb (data) {
  return request({
    url: api.updateRtb,
    method: 'post',
    data
  })
}

// 编辑应用ID
// export function updateRtbPlatAppId (data) {
//   return request({
//     url: api.updateRtbPlatAppId,
//     method: 'post',
//     data })
//   }

// 预算平台下拉框数据请求
export function getApiPlatformList () {
  return request({
    url: api.apiPlatform,
    method: 'get'
  })
}
export function getRtbTreeData (data) {
  return request({
    url: api.getRtbTreeData,
    method: 'post',
    data
  })
}
export function updateConfiguredSts (data) {
  return request({
    url: api.updateConfiguredSts,
    method: 'post',
    data
  })
}
export function reportDetails (data) {
  return request({
    url: api.reportDetails,
    method: 'post',
    data
  })
}
export function updateDspSts (data) {
  return request({
    url: api.updateDspSts,
    method: 'post',
    data
  })
}
export function batchChangePlaceSts (data) {
  return request({
    url: api.batchChangePlaceSts,
    method: 'post',
    data
  })
}
export function replacerInfo (data) {
  return request({
    url: api.replacerInfo,
    method: 'post',
    data
  })
}
