/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-19 17:05:49
 * @FilePath: /apiForManage/media.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  // 带app信息的app列表(之前的app报表)
  appList: '/app/list',
  // 修改广告位
  placeUpdate: '/place/update',
  // 行业列表
  tradeList: '/app/tradeList',
  // 修改app信息
  appUpdate: '/app/update',
  // 添加app
  appAdd: '/app/add',
  // 添加广告位
  addPlace: '/place/add',
  // 批量添加广告位
  batchAddPlace: '/place/batchAdd',
  // 获取可绑定的应用列表
  bindAppList: '/app/bindAppList',
  // 应用关联
  appBind: '/app/appBind'
}

export default api

export function getAppList () {
  return request({
    url: api.appList,
    method: 'get'
  })
}
export function getAppLists (data) {
  return request({
    url: api.appListByUser,
    method: 'post',
    data
  })
}
export function placeUpdate (data) {
  return request({
    url: api.placeUpdate,
    method: 'post',
    data
  })
}
export function industrySelect () {
  return request({
    url: api.tradeList,
    method: 'get'
  })
}
export function appUpdate (data) {
  return request({
    url: api.appUpdate,
    method: 'post',
    data
  })
}
export function appAdd (data) {
  return request({
    url: api.appAdd,
    method: 'post',
    data
  })
}
export function addPlace (data) {
  return request({
    url: api.addPlace,
    method: 'post',
    data
  })
}
export function batchAddPlace (data) {
  return request({
    url: api.batchAddPlace,
    method: 'post',
    data
  })
}

export function getBindAppList (data) {
  return request({
    url: api.bindAppList,
    method: 'post',
    data
  })
}
export function appBind (data) {
  return request({
    url: api.appBind,
    method: 'post',
    data
  })
}
