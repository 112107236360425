<template>
  <div class="rtb-config-container">
    <RtbConfigSearch @handleSearch="handleSearch"/>
    <RtbConfigTable ref="table"/>
  </div>
</template>

<script>
import RtbConfigSearch from './RtbConfigSearch'
import RtbConfigTable from './RtbConfigTable'
export default {
  components: { RtbConfigSearch, RtbConfigTable },
  methods: {
    handleSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.handleSearch(query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.rtb-config-container{
  padding: 0 10px;
}
</style>
