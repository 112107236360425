<template>
  <div class="search-wrapper">
    <a-range-picker
      style="width: 260px"
      class="search_item"
      valueFormat="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    />
    <!-- 广告平台 -->
    <m-select-more
      class="search_item"
      style="width: 220px"
      v-model="query.platIdList"
      :allData="platList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="广告平台"
      :showSelectedPart="true"
      :canSelectAll="true"
      @change="changePlatId"
    />
    <!-- 所属账户 -->
    <m-select-more
      class="search_item"
      style="width: 220px"
      v-model="query.platAccountIdList"
      :allData="platAccountList"
      :searchById="false"
      :hasIcon="false"
      :showId="false"
      label="所属账户"
      :showSelectedPart="true"
      :canSelectAll="true"
    />
    <!-- 搜索功能 -->
    <a-input class="search_item" placeholder="请输入搜索广告源名称或广告位ID" style="width: 280px" allowClear v-model.trim="query.name" />
    <a-button type="primary" @click="handleSearch">
      查询
    </a-button>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
import { getRtbPlatformList, getRtbPlatAccounts } from '@/api/common'
export default {
  mixins: [mixDate],
  data () {
    return {
      query: {
        dateStart: undefined,
        dateEnd: undefined,
        platIdList: [],
        platAccountIdList: [],
        name: undefined
      },
      platAccountList: [],
      timer: null,
      platList: []
    }
  },
  async created () {
    const hasQuery = this.$route.query && this.$route.query.platId
    this.initTime()
    await this.getPlatList()
    if (hasQuery) {
      this.query.platIdList = ['' + this.$route.query.platId]
    }
    await this.getRtbPlatAccounts()
    if (hasQuery) {
      this.query.platAccountIdList = ['' + this.$route.query.platAccountId]
      this.query.dspId = this.$route.query.dspId
    }
    this.handleSearch(undefined, hasQuery ? this.$route.query.dspId : undefined)
    if (hasQuery) {
      this.$router.push({
        quuery: {}
      })
    }
  },
  methods: {
    handleSearch (e, dspId) {
      this.$emit('handleSearch', {
        ...this.query,
        dspId
      })
    },
    // 获取所有广告平台的账户列表
    // 获取广告平台账户下拉
    async getRtbPlatAccounts () {
      const resp = await getRtbPlatAccounts({
        platIdList: this.query.platIdList
      })
      if (resp.code === 200) {
        const arr = resp.data || []
        this.platAccountList = arr
      }
    },
    async changePlatId (ids) {
      await this.getRtbPlatAccounts()
    },
    // 获取所有广告平台
    async getPlatList () {
      const { data = [] } = await getRtbPlatformList()
      data.forEach((item) => {
        item.id = '' + item.id
      })
      this.platList = data
    },
    // 时间初始化
    initTime () {
      const { endDate, startDate } = this.initDate(0, 0)
      this.rangePickerDate = [startDate, endDate]
      this.query = {
        ...this.query,
        dateStart: startDate,
        dateEnd: endDate
      }
    },
    // 改变时间
    ChangeDate (date, dateString) {
      this.rangePickerDate = [dateString[0], dateString[1]]
      this.query.dateStart = dateString[0]
      this.query.dateEnd = dateString[1]
    },
    // 不可选时间
    disabledDate (current) {
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    }
  }
}
</script>

<style lang="less" scoped>
.search-wrapper {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 10px;
  .search_item{
    margin-right: 10px;
  }
}
</style>
