/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-23 16:54:22
 * @FilePath: /apiForManage/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const flowApi = {
  getSupplierList: '/publisher/list',
  getSupplierPage: '/publisher/page',
  addSupplier: '/publisher/add',
  updateSupplier: '/publisher/update',
  deleteSupplier: '/publisher/delete',
  batchAddSupplier: '/publisher/batchAddPublisher'
}
/**
 * 获取供应商列表
 * @export
 * @param {any} [data={}]
 * @returns
* */
export function getSupplierList (data = {}) {
  return request({
    url: flowApi.getSupplierList,
    method: 'post',
    data
  })
}
/**
 * 获取供应商分页数据
 * @export
 * @param {any} data
 * @returns
* */
export function getSupplierPage (data) {
  return request({
    url: flowApi.getSupplierPage,
    method: 'post',
    data
  })
}
/**
 * 添加供应商
 * @export
 * @param {any} data
 * @returns
 */
export function addSupplier (data) {
  return request({
    url: flowApi.addSupplier,
    method: 'post',
    data
  })
}
/**
 * 编辑供应商
 * @export
 * @param {any} data
 * @returns
* */
export function updateSupplier (data) {
  return request({
    url: flowApi.updateSupplier,
    method: 'post',
    data
  })
}
/**
 * 删除供应商
 * @export
 * @param {any} data
 * @returns
* */
export function deleteSupplier (data) {
  return request({
    url: flowApi.deleteSupplier,
    method: 'post',
    data
  })
}

/**
 * 批量添加供应商
 * @export
 * @param {any} data
 * @returns
* */
export function batchAddSupplier (data) {
  return request({
    url: flowApi.batchAddSupplier,
    method: 'post',
    data
  })
}
