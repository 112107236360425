<template>
  <a-modal
    v-model="addSourceVisible"
    :title="(isAdd === 5 ? '配置' : isAdd === 4 ? '批量配置' : '配置') + '广告源'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="900"
    dialogClass="source_modal"
  >
    <a-spin :spinning="isLoading">
      <template slot="footer">
        <a-button
          v-if="origin === 'https://app.mediatom.cn'"
          style="margin-right: 500px"
          type="link"
          target="_blank"
          href="https://app.mediatom.cn/docs/#/?blogid=125"
        >如何配置广告源?</a-button
        >
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
      <div class="source_wrapper">
        <!-- 右侧内容 start -->
        <div class="source_wrapper_form" :style="{ 'margin-left': '0' }">
          <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
            <!-- 新增、编辑广告源 start -->
            <!-- 新增、编辑广告源 end -->
            <a-form-model ref="ruleForms" :model="congquery" :rules="rules" v-bind="layout">
              <!-- 配置 流量 end -->
              <a-form-model-item label="预算平台">
                {{ formquery.platName }}
              </a-form-model-item>
              <a-form-model-item label="所属账户">
                {{ formquery.accountName }}
              </a-form-model-item>
              <a-form-model-item label="预算名称">
                <a-input style="width: 200px" placeholder="请输入预算名称" v-model.trim="formquery.name" />
              </a-form-model-item>
              <a-form-model-item :label="getAppIdLabel(sourceInfo.platId)" v-if="showAppIds(sourceInfo.platId)">
                {{ formquery.platAppId }}
              </a-form-model-item>
              <a-form-model-item label="广告位ID">
                {{ formquery.platPlaceId }}
              </a-form-model-item>
              <!-- 出价类型 -->
              <a-form-model-item prop="settleType">
                <span slot="label">出价类型</span>
                <a-radio-group name="radioGroup" v-model="congquery.settleType">
                  <a-radio :value="1"> RTB竞价 </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <PricingStrategyForm @clearValidate="changeSet" :query="congquery" @changeQuery="changeCongQuery" />
              <ApiSourceForm :positionId="+formquery.position" :query="congquery" @changeQuery="changeCongQuery" />
              <!-- 渲染方式 -->
              <a-form-model-item
                label="渲染方式"
                v-if="showAdType"
                prop="adStyle"
                :rules="[{ required: true, message: '请选择渲染方式', trigger: 'change' }]"
              >
                <a-select
                  style="width: 200px"
                  placeholder="请选择渲染方式"
                  v-model="congquery.adStyle"
                  showSearch
                  :filter-option="filterOption"
                >
                  <a-select-option
                    v-for="item in adStyleList"
                    :key="item.id"
                    :value="item.id"
                    showSearch
                    :filter-option="filterOption"
                  >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <!-- dealID -->
              <a-form-model-item v-if="+formquery.platId === 997" label="dealID" prop="dealid">
                <a-input style="width: 200px" placeholder="请输入dealID" v-model.trim="congquery.dealid" />
              </a-form-model-item>
              <!-- 模板类型 -->
              <a-form-model-item
                v-if="+formquery.position === 6 || (+formquery.position === 10 && +congquery.adStyle === 1)"
                label="模版类型"
                prop="templateTypeId"
              >
                <a-select style="width: 200px" placeholder="请选择模版类型" v-model="congquery.templateTypeId">
                  <a-select-option v-for="item in templateTypeList" :key="item.id" :value="item.id">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <!-- 左右边距 -->
              <a-form-model-item
                v-if="+formquery.position === 6 || (+formquery.position === 10 && +congquery.adStyle === 1)"
                label="左右边距"
                prop="lrMargin"
              >
                <a-input-number
                  class="inputNumber"
                  style="width: 200px"
                  placeholder="请输入左右边距"
                  v-model.trim="congquery.lrMargin"
                />
              </a-form-model-item>
              <!-- 上下边距 -->
              <a-form-model-item
                v-if="+formquery.position === 6 || (+formquery.position === 10 && +congquery.adStyle === 1)"
                label="上下边距"
                prop="tbMargin"
              >
                <a-input-number
                  class="inputNumber"
                  style="width: 200px"
                  placeholder="请输入上下边距"
                  v-model.trim="congquery.tbMargin"
                />
              </a-form-model-item>
              <!-- 配置 流量 end -->
            </a-form-model>
          </a-form-model>
        </div>
        <!-- 右侧内容 end -->
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import {
  gettemplateTypeList,
  updateConfigured,
  updateConfigured2,
  getApiPlatformList
} from '@/api/aggregate'
import BiddingSvg from '@/assets/icons/bidding.svg?inline'
import AutoSvg from '@/assets/icons/Auto.svg?inline'
import { mapState } from 'vuex'
import { adStyleList } from './list'
import { showAppIds, getAppIdLabel } from '@/utils/autoLabel'
import getList from '@/mixins/getListForManage'
import PricingStrategyForm from '@/components/sourceModal/PricingStrategyForm'
import ApiSourceForm from '@/components/sourceModal/ApiSourceForm'
export default {
  name: 'SourceModal',
  components: { BiddingSvg, AutoSvg, PricingStrategyForm, ApiSourceForm },
  mixins: [getList],
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 }
      },
      // 账户绑定参数
      // 广告源参数
      formquery: {
        appId: '',
        platId: '',
        platAccountId: undefined,
        platAppId: '',
        name: '',
        platPlaceId: undefined,
        templateIds: [],
        settleType: 1,
        templateTypeId: undefined
      },
      congquery: {
        appKey: '',
        slotId: '',
        bidfloor: 0,
        buttonSize: 50,
        settleType: 1,
        isDownload: 'S',
        lrMargin: 0,
        tbMargin: 0,
        hotspotType: 1,
        sensitivity: 50,
        dealid: '',
        hotspot: 'S',
        adStyle: undefined,
        priorityRace: 'S',
        floatingType: 1,
        floatingValue: undefined
      },
      adStyleList,
      rules: {},
      // 广告平台列表
      platList: [],
      platInfo: {
        id: '',
        accessType: ''
      },
      hide: true,
      // 模板类型列表
      templateTypeList: [],
      isLoading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      // 1: 新增；2：编辑；3：配置,4: 批量配置
      type: Number,
      default: 0
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    query: {
      default: () => ({}),
      type: Object
    },
    hideInfo: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    sourceInfo: {
      handler (val) {
        // 编辑状态下初始化广告源参数
        if (this.isAdd === 5) {
          const form = JSON.parse(JSON.stringify(val))
          const { platName = '', accountName = '', name = '', platAppId = '', platPlaceId = '', position = '' } = form
          this.formquery.platName = platName
          this.formquery.accountName = accountName
          this.formquery.name = name
          this.formquery.platAppId = platAppId
          this.formquery.platPlaceId = platPlaceId
          this.formquery.position = position
          this.congquery = form
        } else {
          this.formquery = JSON.parse(JSON.stringify(val))
        }
        // UI组件绑定String格式
        this.formquery.templateIds && (this.formquery.templateIds = this.formquery.templateIds.map((item) => '' + item))
      },
      deep: true,
      immediate: true
    },
    isAdd: {
      handler (val) {
        if (val === 4) {
          let hideInfoPosition = this.hideInfo.map((x) => x.position)
          hideInfoPosition = [...new Set(hideInfoPosition)]
          if (!this.hideInfo.some((it) => it.accessType === 0)) {
            this.hide = false
          } else if (
            this.hideInfo.some((it) => it.accessType === 0) &&
            this.hideInfo.length >= 2 &&
            // 去重后样式的长度大于1表示样式不唯一
            hideInfoPosition.length > 1
          ) {
            this.hide = false
          }
          !this.hide && this.initDefault()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      checkable: (state) => state.user.checkable,
      sensitivity: (state) => state.asyncPermission.sensitivity
    }),
    showAdType () {
      const show = +this.formquery.position === 10
      return show
    },
    addSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    showDrawBtnPos () {
      /**
       * 展示条件：
       * 插屏 && 广告平台为API
       * */
      const show = +this.formquery.position === 3
      return show
    }
  },
  async mounted () {
    await this.platformLinkList()
    if (this.isAdd === 5) {
      this.platInfo = this.platList.find((item) => item.id === this.congquery.platId)
    }
    this.formquery.appId = this.query.appId
    this.gettemplateTypeList()
  },
  methods: {
    showAppIds,
    getAppIdLabel,
    changeCongQuery (e) {
      this.congquery = JSON.parse(JSON.stringify(e))
    },
    initDefault () {
      this.hideInfo.forEach((item, index) => {
        if (item.position === '1') {
          // 开屏 广告交互 开启
          item.hotspot = 'A'
          // 广告交互样式 摇一摇
          item.hotspotType = 2
          // 灵敏度  50
          item.sensitivity = 50
        } else if (item.position === '2') {
        } else if (item.position === '3') {
          // 广告交互        关闭
          item.hotspot = 'S'
        } else if (item.position === '4') {
          // 原生自渲染
        } else if (item.position === '5') {
          // 激励视频
        } else if (item.position === '6') {
          // 原生模板
          // 模板类型      图文混合
          item.templateTypeId = 0
          // 左右边距           0
          item.lrMargin = 0
          // 上下边距           0
          item.tbMargin = 0
        } else if (item.position === '7') {
          // 全屏视频
        } else if (item.position === '8') {
          // Draw视频
        } else if (item.position === '10') {
          // 原生混合
          // 渲染方式       自渲染
          item.adStyle = 2
        }
      })
    },
    // 切换竞价底价策略 清空校验
    changeSet (data) {
      this.$refs.ruleForms.clearValidate()
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 获取模版类型列表
    async gettemplateTypeList () {
      const resp = await gettemplateTypeList()
      this.templateTypeList = resp.data || []
    },
    // 获取广告平台列表
    async platformLinkList () {
      this.isLoading = true
      const resp = await getApiPlatformList()
      const arr = resp.data || []
      this.platList = arr
      this.isLoading = false
    },
    handleCancel () {
      this.addSourceVisible = false
    },
    // 确认按钮点击事件
    async handleSubmit () {
        this.$refs.ruleForms.validate((validate) => {
          if (!validate) return false
          this.handleAddOrEdit()
        })
    },
    // 添加或编辑广告源
    async handleAddOrEdit () {
      if (this.isAdd === 5) {
        // 配置
        this.congquery.name = this.formquery.name
        const resp = await updateConfigured(this.congquery)
        if (resp.code === 200) {
          this.$message.success('配置成功!')
          this.$emit('changeSource')
          this.addSourceVisible = false
        }
      } else if (
        this.isAdd === 4 &&
        (!this.hideInfo.some((it) => it.accessType === 0) ||
          (this.hideInfo.some((it) => it.accessType === 0) && this.hideInfo.length >= 2))
      ) {
        // 配置
        const params = this.hideInfo.map((item) => ({
          accessType: item.accessType,
          appId: item.appId,
          id: this.formquery.id,
          name: this.formquery.name,
          placeIdList: [item.placeId],
          ...this.congquery
        }))
        const resp = await updateConfigured2(params)
        if (resp.code === 200) {
          this.$message.success('配置成功!')
          this.$emit('changeSource')
          this.addSourceVisible = false
        }
      } else {
        // 配置
        const sourceArray = { link: [] }
        sourceArray.link.push({
          ...this.sourceInfo,
          id: this.formquery.id,
          name: this.formquery.name,
          placeIdList: [this.formquery.placeId],
          ...this.congquery
        })
        const resp = await updateConfigured2(sourceArray.link)
        if (resp.code === 200) {
          this.$message.success('配置成功!')
          this.$emit('changeSource')
          this.addSourceVisible = false
        }
      }
    }
  }
}
</script>
<style lang="less">
.source_modal .ant-modal-body {
  padding: 10px 0 10px 10px;
  position: relative;
  background: #eee;
}
</style>

<style lang="less" scoped>
.source_wrapper {
  top: 0;
  left: 0;
  height: 60vh;
  overflow-y: hidden;
  overflow-x: hidden;
  .source_wrapper_plat {
    border-radius: 5px;
    background-color: #fff;
    width: 240px;
    position: absolute;
    height: calc(100% - 0px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .source_wrapper_form {
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    height: 100%;
    overflow-y: auto;
    padding-top: 10px;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    ::v-deep {
      .el-radio.is-bordered {
        margin-right: 0px;
      }
      .el-radio.is-bordered.is-checked {
        background: fade(@primary-color, 10%);
        border-radius: 5px;
        border: 1px solid fade(@primary-color,  30%);
      }
      .el-radio__input.is-checked + .el-radio__label {
        color: @primary-color;
      }
      .el-radio__input {
        display: none;
      }
    }
    .inputNumber {
      border-radius: 5px;
      height: 36px;
      line-height: 36px;
    }
  }
}
.ant-popover-inner-content {
  padding: 1px 16px;
  color: rgba(0, 0, 0, 0.65);
}
</style>
