/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-23 17:56:04
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-24 11:59:51
 * @FilePath: /mediatom-web/src/apiForManage/flow/place.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const flowApi = {
  getPlaceList: '/place/list',
  getPlacePage: '/place/page',
  updatePlace: '/place/update',
  deletePlace: '/place/delete',
  addPlace: '/place/add',
  batchAddPlace: '/place/batchAdd'
}
/**
 * 获取广告位列表
 * @export
 * @param {array} [data=[]] 供应商ID
 * @returns
* */
export function getPlaceList (data) {
  return request({
    url: flowApi.getPlaceList,
    method: 'post',
    data
  })
}
/**
 * 获取广告位列表
 * @export
 * @param {object} data
 * @returns
* */
export function getPlacePage (data) {
  return request({
    url: flowApi.getPlacePage,
    method: 'post',
    data
  })
}
/**
 * 更新广告位
 * @export
 * @param {any} data
 * @returns
* */
export function updatePlace (data) {
  return request({
    url: flowApi.updatePlace,
    method: 'post',
    data
  })
}
/**
 * 删除广告位
 * @export
 * @param {any} data
 * @returns
* */
export function deletePlace (data) {
  return request({
    url: flowApi.deletePlace,
    method: 'post',
    data
  })
}
/**
 * 添加广告位
 * @export
 * @param {any} data
 * @returns
 */
export function addPlace (data) {
  return request({
    url: flowApi.addPlace,
    method: 'post',
    data
  })
}

/**
 * 批量添加广告位
 * @export
 * @param {any} data
 * @returns
 */
export function batchAddPlace (data) {
  return request({
    url: flowApi.batchAddPlace,
    method: 'post',
    data
  })
}
