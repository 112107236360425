<template>
  <a-modal
    v-model="adxPlaceVisible"
    title="配置广告交互策略"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="900"
    dialogClass="adxPlace_modal"
  >
    <a-spin :spinning="isLoading">
      <template slot="footer">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </template>
      <div class="source_wrapper">
        <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
          <TableScreenSourceForm
            :positionId="positionId"
            :platInfo="{ accessType: 1, id: formquery.platId }"
            :query="formquery"
            @changeQuery="changeformquery"
          />
          <OpenScreenSourceForm
            :positionId="positionId"
            :platInfo="{ accessType: 1, id: formquery.platId }"
            :query="formquery"
            @changeQuery="changeformquery"
          />
          <OpenOrTableScreenSourceForm
            :positionId="positionId"
            :platInfo="{ accessType: 1, id: formquery.platId }"
            :query="formquery"
            @changeQuery="changeformquery"
          />
          <HostSpotSourceForm
            :query="formquery"
            :positionId="positionId"
            :platInfo="{ id: formquery.platId, accessType: 1 }"
            @changeQuery="changeformquery"
          />
          <PlatAPISourceForm
            :query="formquery"
            :positionId="positionId"
            :platInfo="{ id: formquery.platId, accessType: 1 }"
            @changeQuery="changeformquery"
          />
          <IncentiveVideoSourceForm
            :query="formquery"
            :positionId="positionId"
            :platInfo="{ id: formquery.platId, accessType: 1 }"
            @changeQuery="changeformquery"
          />
        </a-form-model>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import TableScreenSourceForm from '@/components/sourceModal/TableScreenSourceForm'
import OpenScreenSourceForm from '@/components/sourceModal/OpenScreenSourceForm'
import OpenOrTableScreenSourceForm from '@/components/sourceModal/OpenOrTableScreenSourceForm'
import { mapState } from 'vuex'
import getList from '@/mixins/getList'
import HostSpotSourceForm from '@/components/sourceModal/HotSpotSourceForm'
import PlatAPISourceForm from '@/components/sourceModal/PlatAPISourceForm'
import IncentiveVideoSourceForm from '@/components/sourceModal/IncentiveVideoSourceForm'
import { updatePlaceStyle } from '@/api/aggregate'
export default {
  components: { HostSpotSourceForm, TableScreenSourceForm, OpenScreenSourceForm, OpenOrTableScreenSourceForm, PlatAPISourceForm, IncentiveVideoSourceForm },
  mixins: [getList],
  name: 'SourceModal',
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 16 }
      },
      // 广告源参数
      formquery: {
      },
      // 表单默认值
      formqueryTemp: {
        hotspot: 'S',
        hotspotType: 1,
        sensitivity: 0,
        scaleType: 0,
        respAreaRatio: undefined,
        closeButtonFreq: undefined,
        closeButtonInterval: undefined,
        displayEffectiveCount: undefined,
        displayCountdown: 1,
        drawTypeList: [],
        animateStartTime: undefined,
        animateTime: undefined,
        closeButtonType: 'A',
        buttonPosition: '0',
        countDown: undefined,
        buttonSize: undefined,
        autoClose: 'S',
        closeCountdown: undefined,
        onlyButton: undefined,
        redPackageRain: 'S',
        rdCountdown: undefined,
        rdSkipTime: undefined,
        rdType: 0,
        rdClickTime: undefined,
        rdClickRatio: undefined,
        rdAutoType: 1,
        rdAutoTime: undefined,
        rdAutoskipSts: 'S',
        isFullScreen: 0,
        cpCloseButton: 'S',
        cpDisplayCountdown: 1,
        autoPlayType: 0,
        drawType: '0',
        priorityRace: 'S',
        floatingType: 1,
        floatingValue: undefined
      },
      rules: {},
      // 当前选择的广告平台
      platInfo: {
        name: '',
        id: '',
        accessType: ''
      },
      isLoading: false,
      positionId: undefined
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      async handler (val) {
        if (val) {
          await this.getPlatList()
          const { sourceId, id, platId, appId, placeId, position, adStyle, isAutoDrawing } = this.placeInfo
          this.formquery = {
            sourceId,
            id,
            platId,
            appId,
            placeId,
            position,
            adStyle,
            isAutoDrawing,
            ...this.formqueryTemp
          }
          this.positionId = +this.formquery.position
          this.platInfo = this.platList.find((item) => +item.id === +this.formquery.platId)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin,
      checkable: (state) => state.user.checkable
    }),
    adxPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  async mounted () {
  },
  methods: {
    changeformquery (e) {
      this.formquery = JSON.parse(JSON.stringify(e))
    },
    handleCancel () {
      this.adxPlaceVisible = false
    },
    // 确认按钮点击事件
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const query = {
            ...this.formquery,
            id: this.formquery.sourceId
          }
          updatePlaceStyle(query).then((resp) => {
            if (resp.code === 200) {
              this.$emit('changeInnerPlace')
              this.$message.success('配置成功！')
              this.adxPlaceVisible = false
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.source_wrapper {
  overflow-y: auto;
  max-height: 500px;
  overflow-x: hidden;
  background-color: #fff;
  padding: 15px 0 0 0;
  height: 500px;
}
</style>
